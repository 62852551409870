import { useEffect, useRef } from "react";
import {
  defer,
  useLoaderData,
  useSubmit,
  useActionData,
  useNavigate,
  useParams,
} from "react-router-dom";
import classes from "./CheckoutPage.module.css";
import { Container, Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { BeatLoader } from "react-spinners";
import FormikInputField from "./FormikInputField";
import PrimaryActionButton from "./PrimaryActionButton";
import { useState } from "react";
import CheckoutCountDown from "./CheckoutCountDown";
import CheckoutTelInput from "./CheckoutTelInput";
import displayToastErrors from "../utils/displayToastErrors";
import RequestProgressBar from "./RequestProgressBar";
import { toast } from "react-toastify";
import PromoCode from "./PromoCode";
import useDocumentTitle from "../custom-hooks/useDocumentTitle";

export default function CheckoutPage() {
  const [sdkReady, setSdkReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [promoCodeApplied, setPromoCodeApplied] = useState(false);
  const [convenienceFeeApplied, setConvenienceFeeApplied] = useState(false);
  const { setDoucmentTitle } = useDocumentTitle(
    `Eventgooze | Events | Checkout`
  );
  const buttonRef = useRef(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [startProgressBar, setStartProgressBar] = useState(true);
  const [completeProgressBar, setCompleteProgressBar] = useState(false);
  let { order_id: orderId } = useParams();
  const loaderData = useLoaderData();
  const actionData = useActionData();
  const navigate = useNavigate();
  const submit = useSubmit();
  const [country, setCountry] = useState("");
  const [eventDetails, setEventDetails] = useState(null);

  useEffect(() => {
    // Function to dynamically load the SDK
    const loadSdk = () => {
      const script = document.createElement("script");
      script.src = "https://www.payhere.lk/lib/payhere.js";
      script.onload = () => {
        setSdkReady(true); // Set the flag to true once the script is loaded
      };
      document.body.appendChild(script);
    };

    // Check if the SDK is already loaded
    if (window.payhere) {
      setSdkReady(true);
    } else {
      loadSdk();
    }
  }, []);

  useEffect(() => {
    const { checkoutDetails } = loaderData;

    checkoutDetails
      .then(({ response }) => {
        const promotion = response.data?.promotion;
        const convenience_fee = response.data?.convenience_fee;

        if (promotion) {
          setPromoCodeApplied(true);
        } else {
          setPromoCodeApplied(false);
        }

        if (convenience_fee) {
          setConvenienceFeeApplied(true);
        } else {
          setConvenienceFeeApplied(false);
        }

        setEventDetails(response.data);
      })
      .catch(({ error }) => {
        // For now I'm not displaying the error message to the user
        // as it might confuse the user.
        // displayToastErrors(
        //   error?.response?.data?.message || "Faild to load data!"
        // );
      })
      .finally(() => {
        setCompleteProgressBar(true);
      });
  }, [loaderData]);

  const onSubmit = (values) => {
    setIsLoading(true);
    setCountry(values.country);

    const data = {
      user_profile: {
        user: {
          first_name: values.first_name.trim().split(" ")[0],
          last_name: values.last_name.trim().split(" ")[0],
          email: values.email,
        },
        contact_number: values.phone,
        country: values.country,
        city: values.city,
        address: values.address,
      },
    };

    submit(
      { intent: "initiate-payment", data: JSON.stringify(data) },
      { method: "post", action: `/checkout/${orderId}` }
    );
  };

  useEffect(() => {
    if (actionData && actionData.intent === "initiate-payment") {
      if (actionData.status === "ok") {
        const notifyUrl = actionData.response.data?.notify_url;

        if (notifyUrl == "/complete-order") {
          // if a 100% discount is applied, navigate_url will be "/complete-order"
          // in that case, navigate to the complete order page
          navigate(`/complete-order/${orderId}`);
        } else {
          const payhereData = {
            sandbox: process.env.REACT_APP_SANDBOX_MODE_ENABLED === "true",
            country,
            ...actionData.response.data,
          };

          // Show the payhere.js popup, when "PayHere Pay" is clicked
          if (sdkReady) {
            window.payhere.startPayment(payhereData);
          }
        }
      } else {
        displayToastErrors(
          actionData?.error?.response?.data?.message,
          "Faild to initiate the order!"
        );
      }

      setIsLoading(false);
    }
  }, [actionData]);

  if (sdkReady) {
    window.payhere.onCompleted = function onCompleted(orderId) {
      navigate(`/complete-order/${orderId}`);
    };

    // Error occurred
    window.payhere.onError = function onError(error) {
      toast.error(error);
      navigate(`/complete-order/${orderId}`);
    };
  }

  // initial values passed to the form
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    country: "Sri Lanka",
    city: "",
    address: "",
  };

  //form validation rules
  const validationSchema = Yup.object({
    first_name: Yup.string().required("This field is required!"),
    last_name: Yup.string().required("This field is required!"),
    email: Yup.string()
      .email("Invalid email format")
      .required("This field is required!"),
    phone: Yup.string()
      .test("is-valid-phone", "Invalid phone number", (value) => {
        if (!value) return false; // Check if value is not null or undefined
        if (value.startsWith("+94") && value.length === 12) {
          return true; // +94 should have 9 digits after the country code, total length 12
        } else if (value.startsWith("+971") && value.length === 13) {
          return true; // +971 should have 9 digits after the country code, total length 13
        }
        return false; // Fails validation if none of the above conditions are met
      })
      .required("This field is required"),
    country: Yup.string().required("This field is required!"),
    city: Yup.string().required("This field is required!"),
    address: Yup.string().required("This field is required!"),
  });

  return (
    eventDetails && (
      <section className={classes.eventDetailsContainer}>
        <RequestProgressBar
          continuousStart={startProgressBar}
          complete={completeProgressBar}
          hideBackground={false}
        />
        <Container className="customContainer">
          <Row className={`${classes.topBarContainer}`}>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-md-start justify-content-start"
            >
              <span
                className={`material-symbols-outlined ${classes.backButtonIcon}`}
                onClick={() =>
                  navigate(
                    `/events/${eventDetails.event_id}/${eventDetails.event_slug}`
                  )
                }
              >
                reply
              </span>
              <h1 className={classes.eventTitle}>{eventDetails.event_title}</h1>
            </Col>
            <Col xs={12}>
              <div className={classes.topBannerUnderline}></div>
            </Col>
          </Row>
          <Row>
            <Col xl={8} lg={7} md={6} xs={12}>
              <div className={classes.leftContainer}>
                <Row>
                  <Col></Col>
                  <Col></Col>
                </Row>
                <Row
                  className={`justify-content-between ${classes.subTitleContainer}`}
                >
                  <Col xs="auto" className="d-flex align-items-center">
                    <h4 className={`${classes.pageSubTitle} m-0`}>
                      Billing Details
                    </h4>
                  </Col>
                </Row>
                <p className={classes.billingInstructions}>
                  Please carefully enter your mobile number and email address.
                  We will send your ticket to your email and use your contact
                  number in case we need to inform you about any updates
                  regarding the event.
                </p>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {(formik) => (
                    <Form>
                      <Row className={classes.formRow}>
                        <Col lg={6} md={12} sm={6} xs={12}>
                          <FormikInputField
                            label="First Name"
                            fieldName="first_name"
                            inputType="text"
                            required
                          />
                        </Col>
                        <Col>
                          <FormikInputField
                            label="Last Name"
                            fieldName="last_name"
                            inputType="text"
                            required
                          />
                        </Col>
                      </Row>
                      <Row className={classes.formRow}>
                        <Col lg={6} md={12} sm={6} xs={12}>
                          <FormikInputField
                            label="Email"
                            fieldName="email"
                            inputType="email"
                            required
                          />
                        </Col>
                        <Col>
                          <CheckoutTelInput
                            phoneNumber={phoneNumber}
                            setPhoneNumber={setPhoneNumber}
                            setFieldValue={formik.setFieldValue}
                          />
                        </Col>
                      </Row>
                      <div className={classes}>
                        <FormikInputField
                          label="City"
                          fieldName="city"
                          inputType="text"
                          required
                        />
                      </div>
                      <div className={classes}>
                        <FormikInputField
                          label="Address"
                          fieldName="address"
                          inputType="text"
                          required
                        />
                      </div>
                      <div className={`d-none ${classes.formRow}`}>
                        <FormikInputField
                          label="Country"
                          fieldName="country"
                          inputType="text"
                          required
                          disabled
                        />
                      </div>
                      <button
                        ref={buttonRef}
                        className="d-none"
                        type="button"
                        onClick={() => {
                          // set all fields as touched. Otherwise formik will not validate the fields
                          formik.setTouched({
                            first_name: true,
                            last_name: true,
                            email: true,
                            phone: true,
                            country: true,
                            city: true,
                            address: true,
                          });

                          formik.validateForm().then((errors) => {
                            if (Object.keys(errors).length > 0) {
                              // scroll the page to the top
                              toast.error(
                                "Please fix the errors before submitting!"
                              );
                              window.scrollTo(0, 0);
                            } else {
                              formik.handleSubmit();
                            }
                          });
                        }}
                      ></button>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
            <Col>
              <div className={classes.rightContainer}>
                <Row
                  className={`justify-content-between ${classes.subTitleContainer}`}
                >
                  <Col xs="auto" className="d-flex align-items-center">
                    <h4 className={`${classes.pageSubTitle} m-0`}>
                      Your Order
                    </h4>
                  </Col>
                </Row>
                <PromoCode promoCodeApplied={promoCodeApplied} />
                <Row className="justify-content-between">
                  <Col xs="auto">
                    <p className={classes.orderDetailsGridTitle}>Item</p>
                  </Col>
                  <Col xs="auto">
                    <p className={classes.orderDetailsGridTitle}>Subtotal</p>
                  </Col>
                </Row>
                <div>
                  {eventDetails.ticket_packages.map((packageItem, key) => (
                    <Row key={key} className="justify-content-between">
                      {" "}
                      <Col>
                        <p className={classes.eventDataTitle}>
                          <span className={classes.packageItemTitle}>
                            {packageItem.title}
                          </span>{" "}
                          - {packageItem.quantity}
                        </p>
                      </Col>
                      <Col xs="auto">
                        <p className={classes.eventDataTitle}>
                          {Intl.NumberFormat("en-LK", {
                            style: "currency",
                            currency: "LKR",
                          }).format(packageItem.price * packageItem.quantity)}
                        </p>
                      </Col>
                    </Row>
                  ))}
                  <div className={classes.eventDetailsDivider}></div>
                  <Row className="justify-content-between">
                    <Col xs="auto">
                      <p className={classes.eventDataTitle}>subtotal</p>
                    </Col>
                    <Col xs="auto">
                      <p className={classes.eventDataTitle}>
                        {Intl.NumberFormat("en-LK", {
                          style: "currency",
                          currency: "LKR",
                        }).format(eventDetails.sub_total)}
                      </p>
                    </Col>
                  </Row>
                  {promoCodeApplied && (
                    <>
                      <Row className="justify-content-between">
                        <Col>
                          <p className={classes.eventDataTitle}>
                            promotion applied{" "}
                            <span
                              className={classes.promotionMessage}
                            >{`(${eventDetails.promotion.message})`}</span>
                          </p>
                        </Col>
                        <Col xs="auto">
                          <p className={classes.eventDataTitle}>
                            -{" "}
                            {Intl.NumberFormat("en-LK", {
                              style: "currency",
                              currency: "LKR",
                            }).format(eventDetails.promotion.discount)}
                          </p>
                        </Col>
                      </Row>
                    </>
                  )}
                  {convenienceFeeApplied && (
                    <>
                      <Row className="justify-content-between">
                        <Col>
                          <p className={classes.eventDataTitle}>
                            Convenience Fee{" "}
                            {`(${eventDetails.convenience_fee.rate}%)`}
                          </p>
                        </Col>
                        <Col xs="auto">
                          <p className={classes.eventDataTitle}>
                            {Intl.NumberFormat("en-LK", {
                              style: "currency",
                              currency: "LKR",
                            }).format(eventDetails.convenience_fee.amount)}
                          </p>
                        </Col>
                      </Row>
                    </>
                  )}
                  <div className={classes.grandTotalDivider}></div>
                  <Row className="justify-content-between">
                    <Col xs="auto">
                      <p
                        className={`${classes.eventDataTitle} ${classes.grandTotal}`}
                      >
                        you'll pay
                      </p>
                    </Col>
                    <Col xs="auto">
                      <p
                        className={`${classes.eventDataTitle} ${classes.grandTotal}`}
                      >
                        {Intl.NumberFormat("en-LK", {
                          style: "currency",
                          currency: "LKR",
                        }).format(eventDetails.total_amount)}
                      </p>
                    </Col>
                  </Row>
                  <div className={classes.grandTotalDivider}></div>
                  <div className={classes.paymentGatewayImageContainer}>
                    <img
                      src="https://www.payhere.lk/downloads/images/payhere_short_banner_dark.png"
                      alt="PayHere"
                      width="100%"
                    />
                  </div>
                  <Row className={classes.agreeTermsAndConditionsContainer}>
                    <Col xs="auto">
                      <input
                        type="checkbox"
                        id="agreeTerms"
                        name="agreeTerms"
                        checked={agreeTerms}
                        className={classes.agreeTermsCheckbox}
                        onChange={(e) => setAgreeTerms(e.target.checked)}
                      />
                    </Col>
                    <Col className={classes.agreeTextContainer}>
                      <p className={classes.agreeText}>
                        I have read and agree to the website's{" "}
                        <a
                          className={classes.termsAndConditionsLink}
                          href="/terms-and-conditions"
                          target="__blank"
                        >
                          terms & conditions
                        </a>
                      </p>
                    </Col>
                  </Row>
                  <div
                    onClick={() => {
                      if (!agreeTerms) {
                        displayToastErrors(
                          "Please agree to the terms & conditions"
                        );
                      }
                    }}
                  >
                    <PrimaryActionButton
                      disabled={agreeTerms ? false : true}
                      className={classes.payNowButton}
                      buttonText={
                        isLoading ? <BeatLoader loading size={10} /> : "Pay Now"
                      }
                      onClick={() => buttonRef.current.click()}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    )
  );
}

const checkoutDetailsLoader =
  (sendRequest) =>
  ({ params: { order_id } }) => {
    const params = {};

    return sendRequest("get", `/order/${order_id}/checkout/`, params).then(
      (resp) => {
        if (resp.status === "error") {
          throw resp;
        } else {
          return resp;
        }
      }
    );
  };

export const checkoutLoader = (sendRequest) => async (args) => {
  const checkoutDetails = checkoutDetailsLoader(sendRequest)(args);

  return defer({
    checkoutDetails,
  });
};
