import classes from "./TicketMetaDataContainer.module.css";

const TicketMetaDataContainer = ({ label, icon, data, locationLink }) => {
  return (
    <div className={classes.ticketMetaDataContainer}>
      <div className={`${classes.iconContainer}`}>
        <span className={`material-symbols-outlined`}>{icon}</span>
      </div>
      <div className="d-flex">
        <div>
          <p className={classes.eventMetaData}>
            <span className={classes.eventMetaDataLabel}>{label} :</span>
          </p>
        </div>
        <div>
          <p className={classes.eventMetaData}>
            {data}
            {label === "venue" && locationLink && (
              <a
                href={locationLink}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <span
                  className={`material-symbols-outlined ${classes.openInNewWindowIcon}`}
                >
                  open_in_new
                </span>
              </a>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TicketMetaDataContainer;
