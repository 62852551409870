import classes from "./PastEventsSingleEventComponent.module.css";
import { useEffect, useState } from "react";

const PastEventsSingleEventComponent = ({
  eventThumbnail,
  eventTitle,
  eventStartDate,
  eventLocation,
}) => {
  const [date, setDate] = useState(null);
  const [month, setMonth] = useState(null);
  const [day, setDay] = useState(null);

  useEffect(() => {
    if (eventStartDate) {
      const date = new Date(eventStartDate);
      const day = String(date.getDate()).padStart(2, "0");
      const monthIndex = date.getMonth();
      const year = date.getFullYear();

      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const monthName = monthNames[monthIndex];

      const formattedDate = `${day}-${monthName}-${year}`;
      setDate(formattedDate);
      setMonth(monthName);
      setDay(day);
    }
  }, [eventStartDate]);

  return (
    <div className={classes.singleEventContainer}>
      <div>
        <div
          className={classes.eventThumbnailContainer}
          style={{ backgroundImage: `url(${eventThumbnail})` }}
        ></div>
        <div className={classes.eventDetailsContainer}>
          <h6 className={`${classes.eventName} d-sm-block d-none`}>
            {eventTitle.length > 45
              ? eventTitle.slice(0, 45) + "..."
              : eventTitle}
          </h6>
          <h6 className={`${classes.eventName} d-sm-none d-block`}>
            {eventTitle.length > 30
              ? eventTitle.slice(0, 30) + "..."
              : eventTitle}
          </h6>
          <p className={classes.eventLocation}>
            {" "}
            {eventLocation.length > 25
              ? eventLocation.slice(0, 25) + "..."
              : eventLocation}
          </p>
          <p className={classes.eventLocation}>{date}</p>
        </div>
      </div>
    </div>
  );
};

export default PastEventsSingleEventComponent;
