import { useEffect, useState } from "react";
import {
  defer,
  useLoaderData,
  useSubmit,
  useActionData,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";
import { Container, Row, Col, ToastContainer } from "react-bootstrap";
import classes from "./EventDetails.module.css";
import EventTicketDetails from "./EventTicketDetails";
import PrimaryActionButton from "./PrimaryActionButton";
import TicketMetaDataContainer from "./TicketMetaDataContainer";
import RequestProgressBar from "./RequestProgressBar";
import displayToastErrors from "../utils/displayToastErrors";
import changeDateFormat from "../utils/changeDateFormat";
import { BeatLoader } from "react-spinners";
import useDocumentTitle from "../custom-hooks/useDocumentTitle";
import EventStartsInTimmer from "./EventStartsInTimmer";
import TicketTimeVenueContainer from "./TicketTimeVenueContainer";

export default function EventDetails() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [startProgressBar, setStartProgressBar] = useState(true);
  const [completeProgressBar, setCompleteProgressBar] = useState(false);
  const [eventDetails, setEventDetails] = useState(null);
  const [ticketPackageDetails, setTicketPackageDetails] = useState([]);
  const [startDateAndTime, setStartDateAndTime] = useState("");
  const [endDateAndTime, setEndDateAndTime] = useState("");
  const { setDoucmentTitle } = useDocumentTitle(`Eventgooze | Events`);
  const [total, setTotal] = useState(0);
  const loaderData = useLoaderData();
  const actionData = useActionData();
  const navigation = useNavigation();
  const navigate = useNavigate();
  const submit = useSubmit();
  let { id: eventId, slug: eventSlug } = useParams();

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const { eventDetails } = loaderData;

    eventDetails
      .then(({ response }) => {
        setEventDetails(response.data);
        setStartDateAndTime(new Date(response.data.start_date_and_time));
        setEndDateAndTime(new Date(response.data.end_date_and_time));
        setTicketPackageDetails(response.data.ticket_packages);
      })
      .catch(({ error }) => {
        displayToastErrors(
          error?.response?.data?.message || "Faild to load event details!"
        );
      })
      .finally(() => {
        setCompleteProgressBar(true);
      });
  }, [loaderData]);

  useEffect(() => {
    // set Docuement title
    if (eventDetails) {
      setDoucmentTitle(`Eventgooze | Events | ${eventDetails?.title}`);
    }
  }, [eventDetails]);

  const onSubmit = (e) => {
    e.preventDefault();

    const inputs = Array.from(e.target.elements).filter(
      (input) => input.dataset.packageId
    );

    const selected_packages = inputs
      .map((input) => ({
        package: parseInt(input.dataset.packageId),
        quantity: parseInt(input.value),
      }))
      .filter((item) => item.quantity > 0);

    submit(
      { selected_packages: JSON.stringify(selected_packages) },
      { method: "post", action: `/events/${eventId}/${eventSlug}` }
    );
  };

  useEffect(() => {
    if (actionData) {
      if (actionData.status === "ok") {
        const order_id = actionData?.response?.data?.order_id;
        navigate(`/checkout/${order_id}`);
      } else {
        displayToastErrors(
          actionData?.error?.response?.data?.message,
          "Faild to initiate the order!"
        );
      }
    }
  }, [actionData]);

  return (
    <>
      <RequestProgressBar
        continuousStart={startProgressBar}
        complete={completeProgressBar}
        hideBackground={false}
      />
      {eventDetails && (
        <section className={classes.eventDetailsContainer}>
          <Container className="customContainer">
            <Row>
              <Col xl={8} lg={7} md={6} xs={12}>
                <div className={classes.leftContainer}>
                  <Row>
                    <Col
                      xl="auto"
                      xm={12}
                      className="order-xl-first order-2 d-flex justify-content-center"
                    >
                      <img
                        src={eventDetails.image}
                        alt=""
                        className={classes.eventBannerImage}
                      />
                    </Col>
                    <Col>
                      <div className={classes.eventDataContainer}>
                        <h5 className={classes.eventTitle}>
                          {eventDetails.title}
                        </h5>
                        <TicketTimeVenueContainer
                          startDateAndTime={startDateAndTime}
                          endDateAndTime={endDateAndTime}
                          location={eventDetails.location}
                          locationLink={eventDetails.location_link}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div
                    className={`${classes.eventDescription} d-md-block d-none`}
                    dangerouslySetInnerHTML={{
                      __html: eventDetails.description,
                    }}
                  ></div>
                  <p
                    className={`${classes.eventDescription} d-md-none d-block`}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          eventDetails.description.length > 500 && !isExpanded
                            ? `${eventDetails.description.substring(0, 500)}...`
                            : eventDetails.description,
                      }}
                    ></span>
                    {eventDetails.description.length > 500 && (
                      <div className="d-flex justify-content-center">
                        <span
                          className={classes.readMoreText}
                          onClick={toggleDescription}
                        >
                          {isExpanded ? " Show Less" : " Read More"}
                        </span>
                      </div>
                    )}
                  </p>
                </div>
              </Col>
              <Col>
                <div className={classes.rightContainer}>
                  <div
                    className={`d-md-block d-block ${classes.eventStartsIntimmerContainer}`}
                  >
                    <h4 className={classes.rightSideBarSubTitle}>
                      event starts in
                    </h4>
                    <EventStartsInTimmer
                      startTimeStamp={eventDetails.start_date_and_time}
                    />
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className={classes.ticketDetailsContainer}>
                      <h4 className={classes.rightSideBarSubTitle}>
                        purchase tickets
                      </h4>
                      {ticketPackageDetails &&
                        ticketPackageDetails.map((ticketPackage) => (
                          <EventTicketDetails
                            key={ticketPackage.id}
                            packageId={ticketPackage.id}
                            eventId={eventDetails.id}
                            packageName={ticketPackage.title}
                            unitPrice={parseInt(ticketPackage.price)}
                            setTotal={setTotal}
                            status={ticketPackage.package_status.label}
                          />
                        ))}
                      <Row>
                        <Col>
                          <p className={classes.ticketTotal}>Total</p>
                        </Col>
                        <Col></Col>
                        <Col>
                          <p className={classes.ticketTotal}>
                            {total === 0
                              ? "LKR.00"
                              : Intl.NumberFormat("en-LK", {
                                  style: "currency",
                                  currency: "LKR",
                                }).format(total)}
                          </p>
                        </Col>
                      </Row>
                      <div className={classes.pricingTotalDivider}></div>
                      <div
                        onClick={() => {
                          if (total === 0) {
                            displayToastErrors(
                              "Please select at least one ticket"
                            );
                          }
                        }}
                      >
                        <PrimaryActionButton
                          className={`${classes.BuyTicketsButton}`}
                          disabled={total === 0 ? true : false}
                          type="submit"
                          buttonText={
                            navigation.state === "idle" ? (
                              "Buy Tickets"
                            ) : (
                              <BeatLoader loading size={10} />
                            )
                          }
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
}

const eventDetailsLoader =
  (sendRequest) =>
  ({ params: { id: eventId } }) => {
    const params = {};

    return sendRequest("get", `/events/${eventId}/`, params).then((resp) => {
      if (resp.status === "error") {
        throw resp;
      } else {
        return resp;
      }
    });
  };

export const eventLoader = (sendRequest) => async (args) => {
  const eventDetails = eventDetailsLoader(sendRequest)(args);

  return defer({
    eventDetails,
  });
};

export const eventAction =
  (sendRequest) =>
  async ({ request }) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData);

    const package_data = {
      selected_packages: JSON.parse(data.selected_packages),
    };

    return sendRequest("post", "/order/initiate/", package_data);
  };
