import classes from "./HomePage.module.css";
import { Container, Row, Col } from "react-bootstrap";
import ZeroSearchResultsMessage from "./ZeroSearchResultsMessage";
import AllEventsSingleEventComponent from "./AllEventsSingleEventComponent";
import { useEffect, useState } from "react";
import { defer, useLoaderData } from "react-router-dom";
import displayToastErrors from "../utils/displayToastErrors";
import ticketImage from "../images/eg-logo-lite.png";
import quotes from "../images/quotes.png";
import clientImage from "../images/client-image.webp";
import founderImage from "../images/founder-image2.webp";
import instaIcon from "../images/instagram-icon.png";
import youtubeIcon from "../images/youtube-icon.png";
import linkedinIcon from "../images/linkedin-icon.png";
import facebookIcon from "../images/facebook-icon.png";
import useDocumentTitle from "../custom-hooks/useDocumentTitle";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { contactContext } from "../App";
import PastEventsSingleEventComponent from "./PastEventsSingleEventComponent";
import { Gallery } from "react-grid-gallery";
import img1 from "../images/gallery/image1.webp";
import img2 from "../images/gallery/image2.webp";
import img3 from "../images/gallery/image3.webp";
import img4 from "../images/gallery/image4.webp";
import img5 from "../images/gallery/image5.webp";
import img6 from "../images/gallery/image6.webp";
import img7 from "../images/gallery/image7.webp";
import img8 from "../images/gallery/image8.webp";
import img9 from "../images/gallery/image9.webp";
import img10 from "../images/gallery/image10.webp";

export default function HomePage() {
  const loaderData = useLoaderData();
  const navigate = useNavigate();
  const [eventsList, setEventsList] = useState(null);
  const [pastEventsList, setPastEventsList] = useState(null);
  const { contactModalShow, setContactModalShow, setContactUsDefaultMessage } =
    useContext(contactContext);
  const { setDoucmentTitle } = useDocumentTitle(
    `Eventgooze | Sri Lanka’s most trusted and convenient online ticket booking platform.`
  );

  useEffect(() => {
    const { eventsList, pastEventsList } = loaderData;

    eventsList
      .then(({ response }) => {
        setEventsList(response.data);
      })
      .catch(({ error }) => {
        displayToastErrors(
          error?.response?.data?.message || "Faild to load events!"
        );
      });

    pastEventsList
      .then(({ response }) => {
        setPastEventsList(response.data);
      })
      .catch(({ error }) => {
        // since this is not a critical error, we can ignore it
        // displayToastErrors(
        //   error?.response?.data?.message || "Faild to load events!"
        // );
      });
  }, [loaderData]);

  const images = [
    {
      src: img1,
      width: 660,
      height: 302,
    },
    {
      src: img2,
      width: 455,
      height: 406,
    },
    {
      src: img3,
      width: 403,
      height: 302,
    },
    {
      src: img4,
      width: 403,
      height: 302,
    },
    {
      src: img5,
      width: 403,
      height: 302,
    },
    {
      src: img6,
      width: 900,
      height: 604,
    },
    {
      src: img7,
      width: 900,
      height: 604,
    },
    {
      src: img8,
      width: 450,
      height: 302,
    },
    {
      src: img9,
      width: 1700,
      height: 604,
    },
    {
      src: img10,
      width: 900,
      height: 604,
    },
  ];

  return (
    <>
      <section className={classes.tobBannerSection}>
        <Container className="customContainer">
          <Row>
            <Col className="d-flex justify-content-sm-start justify-content-center align-items-center order-sm-first order-last">
              <div className={classes.topBannerContentContainer}>
                <h1 className={classes.topBannerTitle}>
                  Sri Lanka’s most trusted and convenient <br />
                  online ticket booking platform.
                </h1>
                <p className={classes.topBannerDescription}>
                  Your dream event is just a few clicks away. Don’t just dream
                  it—secure your seat now!
                </p>
              </div>
            </Col>
            <Col
              sm="auto"
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <img
                src={ticketImage}
                alt="ticket-image"
                className={classes.topBannerImage}
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/* hide this section if there are no upcoming events. And show past events section instead */}
      {eventsList && eventsList.length > 0 ? (
        <section className={classes.latestEventsSection}>
          <Container className="customContainer">
            <h3
              className={`${classes.sectionTitle} ${classes.latestEventsSectionTitle}`}
            >
              Latest Events
            </h3>
            <div className={classes.eventsContainer}>
              <Row className="gx-sm-5 gx-3">
                {eventsList.map((event, key) => (
                  <Col
                    xl={3}
                    lg={4}
                    sm={6}
                    xs={6}
                    className="d-flex justify-content-center"
                    key={key}
                  >
                    <AllEventsSingleEventComponent
                      eventId={event.id}
                      eventThumbnail={event.image}
                      eventTitle={event.title}
                      eventStartDate={event.start_date_and_time}
                      eventSlug={event.event_slug}
                      eventLocation={event.location}
                      ticketPackages={event.ticket_packages}
                    />
                  </Col>
                ))}
              </Row>
            </div>
            {eventsList && eventsList.length > 0 && (
              <div className="d-flex justify-content-center">
                <div
                  className={classes.viewAllEventsTextButton}
                  onClick={() => navigate("/events")}
                >
                  <p>View All Events</p>
                </div>
              </div>
            )}
          </Container>
        </section>
      ) : (
        <section className={classes.latestEventsSection}>
          {pastEventsList && pastEventsList.length > 0 && (
            <Container className="customContainer">
              <div className={classes.pastEventsTitleSection}>
                <h3
                  className={`${classes.sectionTitle} ${classes.pastEventsSectionTitle}`}
                >
                  Our successful stories
                </h3>
                <p className={classes.pastEventsSubHeading}>
                  Every event brings a new experience. We evolve, we improve, we
                  celebrate. Cheers to Eventgooze!
                </p>
              </div>
              <div className={classes.eventsContainer}>
                <Row className="gx-sm-5 gx-3">
                  {pastEventsList.map((event, key) => (
                    <Col
                      xl={3}
                      lg={4}
                      sm={6}
                      xs={6}
                      className="d-flex justify-content-center"
                      key={key}
                    >
                      <PastEventsSingleEventComponent
                        eventId={event.id}
                        eventThumbnail={event.image}
                        eventTitle={event.title}
                        eventStartDate={event.start_date_and_time}
                        eventLocation={event.location}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
              <div className="d-flex justify-content-center">
                <div
                  className={classes.viewAllEventsTextButton}
                  onClick={() => navigate("/#")}
                >
                  <p>View More</p>
                </div>
              </div>
            </Container>
          )}
        </section>
      )}
      <section className={classes.clientTestimonialSection}>
        <Container className="overflow-hidden customContainer">
          <h3
            className={`${classes.sectionTitle} ${classes.testimonialSectionTitle}`}
          >
            Let our clients speak
          </h3>
          <Row className="gx-5">
            <Col lg="auto" xs="12" className="d-flex justify-content-center">
              <div
                className={classes.clientImageContainer}
                style={{ backgroundImage: `url(${clientImage})` }}
              >
                <div className={classes.clientDetailsContainer}>
                  <h4 className={classes.clientName}>Ramesses Reezy</h4>
                  <div className={classes.clientSocialLinksContainer}>
                    <div className={classes.clientSocialIconContainer}>
                      <a
                        href="https://www.instagram.com/ramessesreezy/?hl=en"
                        target="blank"
                      >
                        <img src={instaIcon} alt="insta-icon" />
                      </a>
                    </div>
                    <div className={classes.clientSocialIconContainer}>
                      <a
                        href="https://www.youtube.com/channel/UCDV1MaVP0PIN2qwtse3sjAA"
                        target="blank"
                      >
                        <img src={youtubeIcon} alt="youtube-icon" />
                      </a>
                    </div>
                    <div className={classes.clientSocialIconContainer}>
                      <a
                        href="https://www.facebook.com/raemesiz/"
                        target="blank"
                      >
                        <img src={facebookIcon} alt="fb-icon" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="d-flex align-items-center">
              <Row>
                <Col xs="auto">
                  <img
                    className={classes.quoteSymbol}
                    src={quotes}
                    alt="quotes"
                  />
                </Col>
                <Col className={classes.clientReviewContentContainer}>
                  <p className={classes.clientReviewContent}>
                    I recently used Eventgooze to manage the online ticket sales
                    and check-in for my latest event "Iconography Green Room",
                    and I couldn't be more impressed. The platform was
                    incredibly user-friendly, and the team provided excellent
                    customer service throughout the entire process. From start
                    to finish, Eventgooze handled the online ticket selling
                    process seamlessly. Their system was easy to navigate, and
                    the checkout process was quick and efficient. Additionally,
                    the ability to create unlimited promo codes was a huge
                    bonus. It allowed me to run targeted marketing campaigns and
                    boost ticket sales. On event day, the check-in process was
                    smooth and hassle-free. The Eventgooze staff were friendly
                    and efficient, ensuring a seamless experience for both
                    attendees and organizers. Overall, I was extremely satisfied
                    with Eventgooze's services. They offered great value for the
                    price, and their platform made event management a breeze. I
                    highly recommend Eventgooze to anyone planning an event.
                  </p>
                </Col>
                <Col xs="auto" className="d-flex align-items-end">
                  <img
                    className={`${classes.quoteSymbol} ${classes.closingQuoteSymbol}`}
                    src={quotes}
                    alt="quotes"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {/* if there are no upcoming events to show, we show this section in top of this page. So hide out this section from here */}
      {eventsList && eventsList.length > 0 && (
        <section className={classes.latestEventsSection}>
          {pastEventsList && pastEventsList.length > 0 && (
            <Container className="customContainer">
              <div className={classes.pastEventsTitleSection}>
                <h3
                  className={`${classes.sectionTitle} ${classes.pastEventsSectionTitle}`}
                >
                  Our successful stories
                </h3>
                <p className={classes.pastEventsSubHeading}>
                  Every event brings a new experience. We evolve, we improve, we
                  celebrate. Cheers to Eventgooze!
                </p>
              </div>
              <div className={classes.eventsContainer}>
                <Row className="gx-sm-5 gx-3">
                  {pastEventsList.map((event, key) => (
                    <Col
                      xl={3}
                      lg={4}
                      sm={6}
                      xs={6}
                      className="d-flex justify-content-center"
                      key={key}
                    >
                      <PastEventsSingleEventComponent
                        eventId={event.id}
                        eventThumbnail={event.image}
                        eventTitle={event.title}
                        eventStartDate={event.start_date_and_time}
                        eventLocation={event.location}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
              <div className="d-flex justify-content-center">
                <div
                  className={classes.viewAllEventsTextButton}
                  onClick={() => navigate("/#")}
                >
                  <p>View More</p>
                </div>
              </div>
            </Container>
          )}
        </section>
      )}
      <section className={classes.gallerySection}>
        <Container className="overflow-hidden customContainer">
          <div className={classes.galleryTitleSection}>
            <h3
              className={`${classes.sectionTitle} ${classes.gallerySectionTitle}`}
            >
              Memories last forever
            </h3>
            <p className={classes.gallerySubHeading}>
              Not every memory fades in a week. Ours last a lifetime.
            </p>
          </div>
          <Row>
            <Col className="d-md-block d-none">
              <Gallery
                images={images}
                rowHeight="150"
                enableImageSelection={false}
                margin={6}
              />
            </Col>
            <Col className="d-md-none d-block">
              <Gallery
                images={images}
                rowHeight="100"
                enableImageSelection={false}
                margin={3}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className={classes.messageFromFounderSection}>
        <Container className="overflow-hidden customContainer">
          <h3
            className={`${classes.sectionTitle} ${classes.testimonialSectionTitle} ${classes.founderSectionTitle}`}
          >
            Message from our founder
          </h3>
          <Row className="gx-5">
            <Col className="d-flex align-items-center">
              <Row>
                <Col xs="auto">
                  <img
                    className={classes.quoteSymbol}
                    src={quotes}
                    alt="quotes"
                  />
                </Col>
                <Col className={classes.clientReviewContentContainer}>
                  <p
                    className={`${classes.clientReviewContent} ${classes.founderMessage}`}
                  >
                    At Eventgooze, we believe that your event is more than just
                    a gathering; it's a reflection of your passion, dedication,
                    and vision. That's why we've built Eventgooze to be your
                    ultimate partner in event organization. We value your trust
                    and prioritize your needs above all else. Our platform is
                    designed to simplify the complexities of event management,
                    allowing you to focus on what truly matters: creating
                    unforgettable experiences. By choosing Eventgooze, you're
                    not just selecting a platform; you're partnering with a team
                    dedicated to making your events extraordinary. We're
                    confident that once you experience the Eventgooze
                    difference, you'll be eager to collaborate with us again.
                    Thank you for choosing Eventgooze. We're honored to be part
                    of your journey.
                  </p>
                </Col>
                <Col xs="auto" className="d-flex align-items-end">
                  <img
                    className={`${classes.quoteSymbol} ${classes.closingQuoteSymbol}`}
                    src={quotes}
                    alt="quotes"
                  />
                </Col>
              </Row>
            </Col>
            <Col
              lg="auto"
              xs="12"
              className="d-flex justify-content-center order-lg-last order-first"
            >
              <div
                className={classes.clientImageContainer}
                style={{ backgroundImage: `url(${founderImage})` }}
              >
                <div className={classes.clientDetailsContainer}>
                  <h4 className={classes.founderName}>Thilanka Wijesooriya</h4>
                  <div className={classes.clientSocialLinksContainer}>
                    <div className={classes.clientSocialIconContainer}>
                      <a
                        href="https://linkedin.com/in/thilanka-wijesooriya-b2ba771b3"
                        target="blank"
                      >
                        <img
                          src={linkedinIcon}
                          alt="linkedin-icon"
                          style={{ width: "22px" }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

const eventsLoader = (sendRequest) => () => {
  const params = {};

  return sendRequest("get", `/events/`, params).then((resp) => {
    if (resp.status === "error") {
      throw resp;
    } else {
      return resp;
    }
  });
};

const pastEventsLoader = (sendRequest) => () => {
  const params = {};

  return sendRequest("get", `/events/?past=true`, params).then((resp) => {
    if (resp.status === "error") {
      throw resp;
    } else {
      return resp;
    }
  });
};

export const homePageEventsListLoader = (sendRequest) => async (args) => {
  const eventsList = eventsLoader(sendRequest)(args);
  const pastEventsList = pastEventsLoader(sendRequest)(args);

  return defer({
    eventsList,
    pastEventsList,
  });
};
